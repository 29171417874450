import React from "react";
import { Container, Row, Col, Card, Table, Badge } from "react-bootstrap";
import { FaBell, FaDoorOpen, FaSignOutAlt } from "react-icons/fa";
import "./DashboardComponent.css";
import { Link } from "react-router-dom";
const CaretakerDashboard = () => {
  // Example data (can be fetched from API or stored locally)
  const username = "Nyagah";
  const notificationsCount = 5;
  const vacantRoomsCount = 3;
  const tenantsMovingOutCount = 2;
  const notifications = [
    { date: "2024-06-23", from: "John Smith" },
    { date: "2024-06-22", from: "Carl Doe" },
    { date: "2024-06-24", from: "Shad Smith" },
    { date: "2024-06-12", from: "Jose Hose" },
    // Add more notifications as needed
  ];
  const latePayments = [
    { name: "Tenant C", unit: "4", room: "4A" },
    { name: "Tenant D", unit: "6", room: "6D" },
    { name: "Tenant C", unit: "4", room: "4A" },
    { name: "Tenant D", unit: "6", room: "6D" },
    // Add more late payments as needed
  ];

  return (
    <Container className="mt-4">
      <Card className="mb-4 card-shadow bg-primary-gradient text-white">
        <Card.Body>
          <Card.Title>Welcome back, {username}!</Card.Title>
          <Card.Text className="text-light">
            A simple yet elegant digital solution!
          </Card.Text>
        </Card.Body>
      </Card>

      <Row className="mt-4">
        <Col md={4}>
          <Link to="/Notifications">
            <Card className="mb-4 card-shadow bg-info-gradient text-white hover-focus">
              <Card.Body>
                <Card.Title>
                  <FaBell className="me-2" /> Notifications
                </Card.Title>
                <Card.Text>
                  <Badge bg="light" text="dark" pill>
                    {notificationsCount}
                  </Badge>
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </Col>
        <Col md={4}>
          <Link to="/VacantRooms">
            <Card className="mb-4 card-shadow bg-success-gradient text-white hover-focus">
              <Card.Body>
                <Card.Title>
                  <FaDoorOpen className="me-2" /> Vacant Rooms
                </Card.Title>
                <Card.Text>
                  <Badge bg="light" text="dark" pill>
                    {vacantRoomsCount}
                  </Badge>
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </Col>
        <Col md={4}>
          <Link to="/VacantRooms">
            <Card className="mb-4 card-shadow bg-warning-gradient text-white hover-focus">
              <Card.Body>
                <Card.Title>
                  <FaSignOutAlt className="me-2" /> Tenants Moving Out
                </Card.Title>
                <Card.Text>
                  <Badge bg="light" text="dark" pill>
                    {tenantsMovingOutCount}
                  </Badge>
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={6}>
          <Link to="/Notifications">
            <Card className="mb-4 card-shadow bg-light-gradient hover-focus">
              <Card.Body>
                <Card.Title>New Notifications</Card.Title>
                <Table striped hover responsive className="mt-3">
                  <thead className="table-primary-gradient">
                    <tr>
                      <th>Date</th>
                      <th>From</th>
                    </tr>
                  </thead>
                  <tbody>
                    {notifications.map((notification, index) => (
                      <tr key={index}>
                        <td>{notification.date}</td>
                        <td>{notification.from}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Link>
        </Col>
        <Col md={6}>
          <Link to="/Arrears">
            <Card className="mb-4 card-shadow bg-light-gradient hover-focus">
              <Card.Body>
                <Card.Title>Late Payments</Card.Title>
                <Table striped hover responsive className="mt-3">
                  <thead className="table-danger-gradient">
                    <tr>
                      <th>Name</th>
                      <th>Unit</th>
                      <th>Room</th>
                    </tr>
                  </thead>
                  <tbody>
                    {latePayments.map((payment, index) => (
                      <tr key={index}>
                        <td>{payment.name}</td>
                        <td>{payment.unit}</td>
                        <td>{payment.room}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default CaretakerDashboard;
